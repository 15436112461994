<template>
<div class="chats-view">

    <PtyButton id="pty__Chats_titlePart1" style="position: absolute; top: 18px; right: 20px;" :disabled="newMatchesCount === 0" :animate="newMatchesCount > 0" :extra-shadow="newMatchesCount > 0" @click="goToMatches">
        <i class="bx bxs-heart"></i>

        <span style="margin-left: 5px;" v-if="newMatchesCount < 11">{{ newMatchesCount }}</span>
        <span style="margin-left: 5px;" v-else>10+</span>
    </PtyButton>

    <div class="action-selection__content">
        <h1>{{ $t('Chats.titlePart1') }}<span>{{ $t('Chats.titlePart2') }}</span></h1>
        <p>
            {{ $t('Chats.message') }}
        </p>
    </div>

    <div class="chats-header">
        <PtyButton id="pty__Chats_matchesTab" transparent @click="selectedTab = 'matches'" :class="{disabled: selectedTab !== 'matches'}">
            <MatchesIcon />
            <p>{{ $t('Chats.matchesTab') }}</p>
        </PtyButton>

        <PtyButton id="pty__Chats_usersTab" transparent @click="selectedTab = 'users'" :class="{disabled: selectedTab !== 'users'}">
            <UsersIcon />
            <p>{{ $t('Chats.usersTab') }}</p>
        </PtyButton>
    </div>

    <div class="inboxes" v-if="selectedTab === 'matches'">
        <PtyButton transparent block no-padding @click="openChat(match)" v-for="match in matches" :key="match.id" :disabled="match.chat && match.chat.is_closed">
            <div class="inbox">
                <vs-avatar circle badge size="80" history style="min-width: 80px;">
                    <img :src="match.pictureUrl" />
                    <template #badge>
                        <img :src="match.tagIcon" style="width: 32px; height: 32px;" />
                    </template>
                </vs-avatar>
                <div class="right">
                    <div class="inbox__heading">
                        <h3>{{ match.participant.nickname || 'Unknown' }}</h3>
                        <p>
                            <template v-if="match.chat && match.chat.latest_message && match.chat.latest_message && match.chat.latest_message.created_at && new Date(match.chat.latest_message.created_at).getFullYear() > 1970">
                                {{ new Date(match.chat.latest_message.created_at).toLocaleTimeString() }}
                            </template>

                            
                        </p>
                    </div>
                    <div class="inbox__content">
                        <p>
                            <template v-if="match.chat && match.chat.latest_message && match.chat.latest_message.payload && match.chat.latest_message.payload.text">
                                {{ match.chat.latest_message.payload.text }}
                            </template>

                            <template v-else>
                                {{ $t('Chats.noMessages') }}
                            </template>
                        </p>
                        <span class="unread" />
                    </div>
                </div>

            </div>
        </PtyButton>
    </div>

    <div class="inboxes" v-else-if="selectedTab === 'users'">
        <PtyButton transparent block no-padding @click="openChat(participant)" v-for="participant in participants" :key="participant.id">
            <div class="inbox">
                <vs-avatar circle badge size="80" history style="min-width: 80px;">
                    <img :src="participant.pictureUrl" />
                    <template #badge>
                        <img :src="tags[participant.tag]" style="width: 32px; height: 32px;" />
                    </template>
                </vs-avatar>
                <div class="right">
                    <div class="inbox__heading">
                        <h3>{{ participant.nickname || 'Unknown' }}</h3>
                        <p>
                            <template v-if="participant.chat && participant.chat.latest_message && participant.chat.latest_message.created_at && new Date(participant.chat.latest_message.created_at).getFullYear() > 1970">
                                {{ new Date(participant.chat.latest_message.created_at).toLocaleTimeString() }}
                            </template>
                        </p>
                    </div>
                    <div class="inbox__content">
                        <p>
                            <template v-if="participant.chat && participant.chat.latest_message && participant.chat.latest_message.payload && participant.chat.latest_message.payload.text">
                                {{ participant.chat.latest_message.payload.text }}
                            </template>

                            <template v-else>
                                {{ $t('Chats.noMessages') }}
                            </template>
                        </p>
                        <span class="unread" />
                    </div>
                </div>
            </div>
        </PtyButton>
    </div>
</div>
</template>

<script>
import {GlobalEventEmitter} from '@/GlobalEventEmitter';
import {
    fileService,
    matchService,
    partecipantService
} from '@/rest';
import ArdenteIcon from '../assets/images/tags/ardente.png';
import DevilIcon from '../assets/images/tags/devil.png';
import DiscretoIcon from '../assets/images/tags/discreto.png';
import InnamoratoIcon from '../assets/images/tags/innamorato.png';
import InnocenteIcon from '../assets/images/tags/innocente.png';
import MaliziosoIcon from '../assets/images/tags/malizioso.png';
import QueenIcon from '../assets/images/tags/queen.png';
import RomanticoIcon from '../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../assets/images/tags/superdotato.png';
import TimidoIcon from '../assets/images/tags/timido.png';

import MatchesIcon from '../assets/images/icons/matches.svg';
import UsersIcon from '../assets/images/icons/users.svg';

import PtyButton from '../components/PtyButton.vue';
export default {
    name: 'Chats',
    components: {
        PtyButton,

        MatchesIcon,
        UsersIcon
    },
    data: () => ({
        selectedTab: 'matches',

        tags: {
            'ARDENTE': ArdenteIcon,
            'DEVIL': DevilIcon,
            'DISCRETO': DiscretoIcon,
            'INNAMORATO': InnamoratoIcon,
            'INNOCENTE': InnocenteIcon,
            'MALIZIOSO': MaliziosoIcon,
            'QUEEN': QueenIcon,
            'ROMANTICO': RomanticoIcon,
            'SUPERDOTATO': SuperdotatoIcon,
            'TIMIDO': TimidoIcon
        },

        matches: [],
        participants: [],

        newMatchesCount: 0,
        newMatchesTimeout: null,

        newMatchedTimeout: null,
    }),

    methods: {
        async getMatchesCount() {
            let matches = await matchService.get({ // matches to approve
                limit: 11,
            });

            if(matches.data != null) {
                this.newMatchesCount = matches.data.length;
            }

            this.newMatchesTimeout = setTimeout(() => {
                this.getMatchesCount();
            }, 5000);
        },

        goToMatches() {
            this.$router.push({
                name: 'Matches'
            });
        },

        openChat(match) {
            const participantId = match.participant ? match.participant.id : (match.id || null); // if participant is null, then it's a match, otherwise it's a participant
            const chatId = match.chat ? match.chat.id : null;
            const matchId = match.participant ? match.id : null; // if participant is null, then it's a match, otherwise it's a participant
            this.$router.push({
                name: 'Chat',
                params: {
                    participantId,
                    chatId,
                    matchId,
                    participant: {
                        ...(match.participant || match),
                        pictureUrl: match.pictureUrl,
                    },
                }
            });
        },

        async getMatched() {
            try {
                const matches = await matchService.getMatched({
                    limit: 100,
                });
                let newMatches = matches.data || [];

                for (var i = 0; i < newMatches.length; i++) {
                    const match = newMatches[i];
                    match.tagIcon = this.tags[match.participant.tag];
                    if (match.pictureUrl == null) {
                        try {
                            const dlUrl = await fileService.getSignedDownloadURL({
                                name: match.participant.id,
                            })
                            match.pictureUrl = dlUrl.url;
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
                this.matches = newMatches;
            } catch (e) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chats.canNotLoadMatches'),
                    position: 'top-center',
                    border: 'primary',
                });
            }
        },

        async getMatchedDelta(){
            // get last 3 matched (ordered by updated_at desc), and merge with current matches, if non existing add them
            try {
                const matches = await matchService.getMatched({
                    limit: 3,
                    order: 'desc(updated_at)',
                });
                let newMatches = matches.data || [];

                for (var i = 0; i < newMatches.length; i++) {
                    const match = newMatches[i];
                    match.tagIcon = this.tags[match.participant.tag];
                    if (match.pictureUrl == null) {
                        try {
                            const dlUrl = await fileService.getSignedDownloadURL({
                                name: match.participant.id,
                            })
                            match.pictureUrl = dlUrl.url;
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }

                for (i = 0; i < newMatches.length; i++) {
                    const match = newMatches[i];
                    const existingMatch = this.matches.find(e => e.id === match.id);
                    if(existingMatch == null){
                        // add to top
                        this.matches.unshift(match);
                    }
                }
            } catch (e) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chats.canNotLoadMatches'),
                    position: 'top-center',
                    border: 'primary',
                });
            }

            this.newMatchedTimeout = setTimeout(() => {
                this.getMatchedDelta();
            }, 5000);
        },

        async getParticipants() {
            try {
                const participantsResult = await partecipantService.get({
                    limit: 100,
                });
                let participants = participantsResult.data;

                for (var i = 0; i < participants.length; i++) {
                    const participant = participants[i];
                    if (participant.pictureUrl == null) {
                        try {
                            const dlUrl = await fileService.getSignedDownloadURL({
                                name: participant.id,
                            })
                            participant.pictureUrl = dlUrl.url;
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
                this.participants = participants;
            } catch (e) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chats.canNotLoadParticipants'),
                    position: 'top-center',
                    border: 'primary',
                });
            }
        },

        wsMessage(message) {
            const data = JSON.parse(message.data);
            const chatId = data.chat_id;

            // search for participant and set chat last message
            let match = this.matches.find(e => e.chat && e.chat.id === chatId);

            if(!match){
                console.log('no match found for chat id, probably new chat', chatId);
                this.getMatched();
                return;
            }

            //match.chat.latest_message.payload.text
            if(match.chat == null){
                match.chat = {};
            }

            if(match.chat.latest_message == null){
                match.chat.latest_message = {};
            }

            if(match.chat.latest_message.payload == null){
                match.chat.latest_message.payload = {};
            }

            match.chat.latest_message.payload.text = data.text;
            match.chat.latest_message.created_at = Date.now();

            console.log('new message for match', match);

            this.matches.__ob__.dep.notify();
        }
    },

    mounted() {
        this.getMatched();
        this.getParticipants();
        this.getMatchesCount();
        GlobalEventEmitter.$emit('ws-connect');
        GlobalEventEmitter.$on('ws-message', this.wsMessage);
    },

    beforeDestroy() {
        GlobalEventEmitter.$off('ws-message', this.wsMessage);

        if(this.newMatchesTimeout){
            clearTimeout(this.newMatchesTimeout);
            this.newMatchesTimeout = null;
        }

        if(this.newMatchedTimeout){
            clearTimeout(this.newMatchedTimeout);
            this.newMatchedTimeout = null;
        }
    },

    watch: {}
}
</script>

<style>
.inbox {
    padding: 10px 15px;
    width: 100%;
    box-sizing: border-box !important;
}
</style><style scoped>
.chats-view .inboxes .inbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chats-view .inboxes .inbox>* {
    /* i want all items fill height */
    height: 100%;
}

.chats-view .inboxes {
    width: 100%;
}

.chats-view .inboxes .inbox .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 5px;
    flex-grow: 1;
    width: calc(100% - 100px);
}

.chats-view .inboxes .inbox .inbox__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.chats-view .inboxes .inbox .inbox__heading h3 {
    color: #000;
    font-family: "Sofia Pro";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.0375rem;
}

.chats-view .inboxes .inbox .inbox__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    gap: 0.5rem;
}

.chats-view .inboxes .inbox .inbox__content p {
    /* truncate exceeding text */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.chats-view .inboxes .inbox .inbox__content>*,
.chats-view .inboxes .inbox .inbox__heading>*,
.chats-view .inboxes .inbox .inbox_time>* {
    margin: 0 !important;
}

.chats-view .inboxes .inbox .inbox_time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5rem;
}

.chats-view .inboxes .inbox span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.chats-view .inboxes .inbox span.unread {
    background-color: rgba(var(--vs-primary));
}

.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 3rem;
    padding-left: 20px;
    padding-right: 20px;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

h1 span {
    color: rgba(var(--vs-primary));
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
    margin: 0;
}

.chats-view {
    width: 100%;
    padding: 0 !important;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: white;

}

.chats-view .chats-header {
    background: rgba(var(--vs-primary));
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0 10px;
    box-sizing: border-box;
}

.chats-view .chats-header .vs-button p {
    color: #fff !important;
    margin-left: 5px;
}

.chats-view .chats-header .vs-button.disabled p,
.chats-view .chats-header .vs-button.disabled svg>* {
    opacity: 0.5;
}
</style>
